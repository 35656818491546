import {action, computed, observable, runInAction, toJS} from "mobx"
import {Gate} from "./lib/gate/Gate"
import {Action, History, Location} from "history"
import {User, Visitor} from "./lib/gate/interfaces"
import {Form, FormID} from "./interfaces"
import {route} from "./lib/functions/route"
import {DataStore} from "./data/DataStore"
import {EventDispatcher} from "./lib/gate/EventDispatcher"
import {
    deepCloneCurrentEnabledForms,
    removeJsonDataForms,
    removeSchedulesIdsFromJsonData,
    CORE_PROGRAMS_DISABLED,
    COUNTER_DOCUMENT_SENT
} from "./helper/Methods"
import Swal from "sweetalert2"

const forms: Form[] = [
    {
        form_id: "application_career_information",
        title: "APPLICATION / CAREER INFORMATION",
        type: "web",
        enabled: true
    },
    {
        form_id: "document_for_admission",
        title: "Application Admission",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "document_admission_pkg",
        title: "Admission Documents",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "disclosures",
        title: "Disclosure",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "enrollment_agreement_rtb",
        title: "Enrollment RTB/WFL",
        type: "pdf",
        enabled: true,
        order: 0
    },
    {
        form_id: "enrollment_agreement_rtb_lc",
        title: "Enrollment RTB/WFL",
        type: "pdf",
        enabled: true,
        order: 0
    },
    {
        form_id: "enrollment_agreement_hispanic_rtb",
        title: "Enrollment RTB/WFL",
        type: "pdf",
        enabled: true,
        order: 0
    },
    {
        form_id: "enrollment_agreement_rtb_miami",
        title: "Enrollment RTB/WFL",
        type: "pdf",
        enabled: true,
        order: 0
    },
    {
        form_id: "enrollment_agreement_emp_apc",
        title: "Enrollment EMPH/APC",
        type: "pdf",
        enabled: true,
        order: 1
    },
    {
        form_id: "enrollment_agreement_emp_apc_lc",
        title: "Enrollment EMPH/APC",
        type: "pdf",
        enabled: true,
        order: 1
    },
    {
        form_id: "enrollment_agreement_emp_apc_miami",
        title: "Enrollment EMPH/APC",
        type: "pdf",
        enabled: true,
        order: 1
    },
    {
        form_id: "enrollment_agreement_aas",
        title: "Enrollment AAS",
        type: "pdf",
        enabled: true,
        order: 2
    },
    {
        form_id: "application_vaccination_question",
        title: "Vaccination Question",
        type: "web",
        enabled: true
    },
    {
        form_id: "application_upload_docs",
        title: "Application Upload docs",
        type: "web",
        enabled: true
    },
    {
        form_id: "vaccination_policy",
        title: "Vaccination Policy Waiver Request",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "survey_quiz",
        title: "Survey Quiz Form",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "submission",
        title: "submission",
        type: "web",
        enabled: true
    }
]

const tabs = [
    {
        id: "1",
        title: "Career Information",
        tab_id: "career_information",
        tabletTitle: "Career Info",
        items: [
            {
                label: "Program Selection",
                value: "application_career_information"
            }
        ],
        disabled: false
    },
    {
        id: "2",
        title: "Application for Admissions",
        tab_id: "application_for_admissions",
        tabletTitle: "Application Admissions",
        items: [{value: "document_for_admission", label: "Application Admission"}],
        disabled: false
    },
    {
        id: "3",
        title: "Admission Documents",
        tab_id: "admissions_documents",
        tabletTitle: "Admissions Docs",
        items: [
            {value: "document_admission_pkg", label: "Admissions Docs"},
            {value: "disclosures", label: "Disclosure"}
        ],
        disabled: false
    },
    {
        id: "4",
        title: "Enrollment Agreement",
        tab_id: "enrollment_agreement",
        tabletTitle: "Enrollment Agreement",
        items: [
            {
                label: "Enrollment RTB/WFL",
                value: "enrollment_agreement_rtb"
            },
            {
                label: "Enrollment RTB/WFL",
                value: "enrollment_agreement_rtb_lc"
            },
            {
                label: "Hispanic Enrollment RTB",
                value: "enrollment_agreement_hispanic_rtb"
            },
            {
                label: "Enrollment RTB/WFL",
                value: "enrollment_agreement_rtb_miami"
            },
            {
                label: "Enrollment EMPH/APC",
                value: "enrollment_agreement_emp_apc"
            },
            {
                label: "Enrollment EMPH/APC",
                value: "enrollment_agreement_emp_apc_lc"
            },
            {
                label: "Enrollment EMPH/APC",
                value: "enrollment_agreement_emp_apc_miami"
            },
            {
                label: "Enrollment AAS",
                value: "enrollment_agreement_aas"
            }
        ],
        disabled: false
    },
    {
        id: "5",
        title: "Vaccination Policy",
        tab_id: "vaccination_policy",
        tabletTitle: "Vaccination Policy",
        items: [
            {
                label: "Vaccination Question",
                value: "application_vaccination_question"
            },
            {
                label: "Upload Document",
                value: "application_upload_docs"
            },
            {
                label: "Vaccination Policy",
                value: "vaccination_policy"
            }
        ],
        disabled: false
    },
    {
        id: "6",
        title: "Survey Quiz Form",
        tab_id: "survey_quiz_form",
        tabletTitle: "Survey Quiz",
        items: [
            {
                label: "Survey Quiz",
                value: "survey_quiz"
            }
        ],
        disabled: false
    },
    // {
    //     id: "6",
    //     title: "Upload docs",
    //     tabletTitle: "Upload docs",
    //     items: [
    //         {
    //             label: "Documents",
    //             value: "application_upload_docs"
    //         }
    //     ],
    //     disabled: false
    // },
    {
        id: "7",
        title: "Submission",
        tab_id: "submission",
        tabletTitle: "Submission",
        items: [
            {
                label: "Submission",
                value: "submission"
            }
        ],
        disabled: false
    }
]

export class Model {
    public dispatcher = new EventDispatcher()
    public dispatcher2 = new EventDispatcher()
    private validateFunctions: Function[] = []
    private CHECKED_YES = "yes"

    @observable
    public document: UserDocument = {json_data: {}}

    @observable
    public signaturePopup: {
        show: boolean
        signature_key
        mode: "signature" | "initials" | "parent"
    } = {
        show: false,
        signature_key: "",
        mode: "signature"
    }

    @observable
    private form_id: FormID = "application_career_information"

    @observable
    public iframeLoaded = false

    @observable
    public isMainIframeLoading = false

    @observable
    public location: Location

    @observable
    public path: string

    @observable
    public visitor: Visitor

    @observable
    public user: User | undefined

    @observable
    public appSettings: any

    @observable
    public popup = {visible: false, message: ""}

    public childBridge: any

    @observable
    public is_form_submitted = false

    private parentBridge: any

    @observable
    public careerInfo: any

    @observable
    public campusesInfo: any

    @observable
    public programs: any

    @observable
    public programsCorePlus: any

    @observable
    public programsCorePlusPlus: any

    @observable
    public coreProgramsSelectedFromJson: any

    constructor(public gate: Gate, private history: History, public dataStore: DataStore) {
        this.history = history
        history.listen(this.onHistoryChange.bind(this))
        this.onHistoryChange(history.location, "PUSH") // execute one time
        // @ts-ignore
        this.parentBridge = window.parent.bridge
    }

    public getPrograms() {
        return this.programs
    }

    public getCorePlusPrograms() {
        return this.programsCorePlus
    }

    public getCorePlusPlusPrograms() {
        return this.programsCorePlusPlus
    }

    public getCoreProgramsSelectedFromJson() {
        return this.coreProgramsSelectedFromJson
    }

    public enableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = true
                break
            }
        }

        this.dispatcher.dispatch()
    }

    public disableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = false
                break
            }
        }
        this.dispatcher.dispatch()
    }

    public getTabs(): any {
        return tabs.filter(({disabled}) => disabled === false)
    }

    public disableTabs(form_id: any) {
        for (let tab of tabs) {
            if (tab.tab_id === form_id) {
                tab.disabled = true
                break
            }
        }

        this.dispatcher.dispatch()
    }

    public enableTabs(form_id: any) {
        for (let tab of tabs) {
            if (tab.tab_id === form_id) {
                tab.disabled = false
                break
            }
        }
        this.dispatcher.dispatch()
    }

    @action
    public setFormId(id: FormID) {
        console.log("SET FORM ID=", id)
        // this.form_id = id
        this.goTo(`/form/${id}?token=${this.gate.getToken()}&user_id=${this.user?.id}`)
        this.iframeLoaded = false
        this.isMainIframeLoading = false
    }

    public setIsMainIframeLoading() {
        this.isMainIframeLoading = true
    }

    @computed
    public get form(): Form {
        return forms.find((el) => {
            return el.form_id === this.form_id
        })
    }

    public goTo(url: string) {
        console.log(`GO TO URL:${url}`)
        this.history.push(url)
    }

    public getForms(): Form[] {
        return forms
    }

    public get enabledItems(): FormID[] {
        let arr = forms.filter((el) => {
            return el.enabled
        })

        return arr.map((el: Form) => {
            return el.form_id
        })
    }

    public getPreviousFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }
        if (index === 0) {
            return null
        }

        for (let i = index - 1; i >= 0; i--) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    public getNextFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i] && forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }

        if (index + 1 === forms.length) {
            return null
        }

        for (let i = index + 1; i < forms.length; i++) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    private onHistoryChange(location: Location, action: Action) {
        this.path = location.pathname
        this.location = location
        console.log("ON HISTORY Change=", this.path)
        if (route("/form/:id(/)", this.path)) {
            // regex
            const {id} = route("/form/:id(/)*", this.path)
            this.form_id = id
        }
    }

    @computed
    public get planStaffUserId(): number {
        if (this.appSettings && this.appSettings.plan_staff_user_id) {
            return parseInt(this.appSettings.plan_staff_user_id)
        }

        return 0
    }

    @computed
    public get agreementStaffUserId(): number {
        // return 60347
        if (this.appSettings && this.appSettings.agreement_staff_user_id) {
            return parseInt(this.appSettings.agreement_staff_user_id)
        }

        return 0
    }

    @action
    public alert(message: string) {
        this.popup.visible = true
        this.popup.message = message
    }

    public get isStaff(): boolean {
        if (!this.visitor) {
            return false
        }
        const role = this.visitor.role
        if (role === "staff" || role === "admin") {
            return true
        }

        return false
    }

    public async loadAppSettings() {
        let r = await this.gate.request("/app-settings/get")
        this.appSettings = r.data.json_data
    }

    public async loadMe(): Promise<void> {
        let r = await this.gate.request("/users/get-me")
        if (!r.success) {
            this.alert("API Error!" + JSON.stringify(r.errors))
        }
        this.visitor = r.data
    }

    public async loadUser(user_id: number): Promise<void> {
        let r = await this.gate.request(`/users/${user_id}/get`)
        this.user = r.data
    }

    public async loadCareerInformation(): Promise<void> {
        const {data} = await this.gate.request(`/careers`)
        this.careerInfo = data
    }

    public async loadCampuses(): Promise<void> {
        const {data} = await this.gate.request(`/campuses`)
        this.campusesInfo = data
    }

    public getHistory(): History {
        return this.history
    }

    public async submit(): Promise<void> {
        try {
            let json_data
            json_data = this.document.json_data
            const id = this.user.doc.id
            json_data.isSubmitted = true
            json_data.submittedForms = deepCloneCurrentEnabledForms(json_data, forms)
            const responseSave = await this.gate.request(`/docs/${id}/counter/edit`, {
                data: {json_data}
            })

            if (responseSave.success) {
                const {
                    data: {json_data: jsonDataResponse}
                } = responseSave
                json_data.counterDocumentSent = jsonDataResponse.counterDocumentSent
                if (
                    jsonDataResponse.counterDocumentSent === COUNTER_DOCUMENT_SENT.firstTime &&
                    json_data.isSurveyQuizSeccondAttempt &&
                    json_data.isSurveyQuizAnswered
                ) {
                    const responseSubmit = await this.gate.request(`/users/${this.user.id}/submit`)
                    if (responseSubmit.success) {
                        await Swal.fire({
                            icon: "success",
                            title: "Application submitted",
                            text:
                                "Thank you for submit your application. An advisor will contact you soon."
                        })
                    } else {
                        delete json_data.isSubmitted
                    }
                } else if (
                    json_data.isSurveyQuizSeccondAttempt &&
                    json_data.isSurveyQuizAnswered &&
                    jsonDataResponse.counterDocumentSent > COUNTER_DOCUMENT_SENT.firstTime
                ) {
                    const responseSubmit = await this.gate.request(
                        `/users/${this.user.id}/submit/retake/quiz`
                    )
                    if (responseSubmit.success) {
                        delete json_data.isSurveyQuizSeccondAttempt
                        await Swal.fire({
                            icon: "success",
                            title: "Survey Quiz Submitted",
                            text: "Thank you for retake the survey quiz."
                        })
                    } else {
                        delete json_data.isSubmitted
                    }
                } else {
                    const responseSubmit = await this.gate.request(`/users/${this.user.id}/submit`)
                    if (responseSubmit.success) {
                        await Swal.fire({
                            icon: "success",
                            title: "Application submitted",
                            text:
                                "Thank you for submit your application. An advisor will contact you soon."
                        })
                    } else {
                        delete json_data.isSubmitted
                    }
                }
                this.dispatcher.dispatch()
                this.dispatcher2.dispatch()
            }
        } catch (error) {}
        runInAction(() => {
            this.is_form_submitted = true
        })
    }

    public async saveAgreement(): Promise<boolean> {
        let json_data
        const id = this.user.doc.id
        if (this.form.type === "web") {
            // if (!this.validate()) {
            //     return false
            // }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }
        json_data.accepted = true
        let r: any = await this.gate.request(`/docs/${id}/edit`, {
            data: {json_data}
        })
        if (r.success) {
            const {
                data: {
                    json_data: {accepted}
                }
            } = r

            json_data.accepted = accepted
        } else {
            if (json_data.accepted) {
                delete json_data.accepted
            }
        }

        return r.success
    }

    public async getTuitionsByCampusCorePrograms(jsonData) {
        const {extra, corePlus, corePlusPlus} = jsonData
        const [, aasBroadCasting, , , , aasGedEd, aasNonSports] = CORE_PROGRAMS_DISABLED
        let newJsonDataInformation = {
            tuitionDataProgram: {},
            tuitionCorePlusDataProgram: {},
            tuitionCorePlusPlusDataProgram: {}
        }
        // const [radioAndTelevision, hispanicRadionAndTelevision] = this.ENROLLMENT_PROGRAMS
        const {
            program: {id: programId},
            campus: {id: campusId}
        } = extra
        const tuitionProgram = await this.generateTuituonByCampusAndProgram(campusId, programId)
        newJsonDataInformation.tuitionDataProgram = tuitionProgram
        if (corePlus && corePlus === this.CHECKED_YES) {
            const {
                corePlusProgramSelected: {id: corePlusProgramId}
            } = extra
            const tuitionCorePlusProgram = await this.generateTuituonByCampusAndProgram(
                campusId,
                corePlusProgramId
            )
            newJsonDataInformation.tuitionCorePlusDataProgram = tuitionCorePlusProgram
        } else if (corePlusPlus && corePlusPlus === this.CHECKED_YES) {
            const {
                corePlusProgramSelected: {id: corePlusProgramId},
                corePlusPlusProgramSelected: {id: corePlusPlusProgramId}
            } = extra
            const tuitionCorePlusProgram = await this.generateTuituonByCampusAndProgram(
                campusId,
                corePlusProgramId
            )
            const tuitionCorePlusPlusProgram = await this.generateTuituonByCampusAndProgram(
                campusId,
                corePlusPlusProgramId
            )
            newJsonDataInformation.tuitionCorePlusDataProgram = tuitionCorePlusProgram
            newJsonDataInformation.tuitionCorePlusPlusDataProgram = tuitionCorePlusPlusProgram
        } else if (jsonData.program === aasBroadCasting || jsonData.program === aasGedEd) {
            if (jsonData.program === aasBroadCasting) {
                const tuitionCorePlusPlusProgram = await this.generateTuituonByCampusAndProgramAASRTBCoreProgram(
                    campusId,
                    programId,
                    {
                        program: aasBroadCasting
                    }
                )
                newJsonDataInformation.tuitionCorePlusPlusDataProgram = tuitionCorePlusPlusProgram
            } else {
                const tuitionCorePlusPlusProgram = await this.generateTuituonByCampusAndProgram(
                    campusId,
                    programId
                )
                newJsonDataInformation.tuitionCorePlusPlusDataProgram = tuitionCorePlusPlusProgram
            }
        } else if (jsonData.program === aasNonSports) {
            const {
                corePlusProgramSelected: {id: corePlusProgramId},
                corePlusPlusProgramSelected: {id: corePlusPlusProgramId}
            } = extra
            const tuitionCorePlusProgram = await this.generateTuituonByCampusAndProgram(
                campusId,
                corePlusProgramId
            )
            const tuitionCorePlusPlusProgram = await this.generateTuituonByCampusAndProgram(
                campusId,
                corePlusPlusProgramId
            )
            newJsonDataInformation.tuitionCorePlusDataProgram = tuitionCorePlusProgram
            newJsonDataInformation.tuitionCorePlusPlusDataProgram = tuitionCorePlusPlusProgram
        }

        return newJsonDataInformation
    }

    public onCalculateSurveyQuizResults = () => {
        let json_data = this.document.json_data
        json_data.quiz.correct = 0
        json_data.quiz.incorrect = 0
        for (let i = 0; i < json_data.quiz.section1.questions.length; i++) {
            const element = json_data.quiz.section1.questions[i]
            if (element.indexSelected < 0) {
                json_data.quiz.incorrect += 1
            } else if (
                element.answers[element.indexSelected].isCorrect &&
                element.answers[element.indexSelected].answerStatus === true
            ) {
                json_data.quiz.correct += 1
                json_data.quiz.totalCorrectPoints +=
                    element.answers[element.indexSelected].punctuation
                // json_data.quiz.incorrect = json_data.quiz.incorrect === 0 ? 0 : json_data.quiz.incorrect -= 1
            } else {
                json_data.quiz.incorrect += 1
            }
        }

        for (let i = 0; i < json_data.quiz.section2.questions.length; i++) {
            const element = json_data.quiz.section2.questions[i]
            if (element.indexSelected < 0) {
                json_data.quiz.incorrect += 1
            } else if (
                element.answers[element.indexSelected].isCorrect &&
                element.answers[element.indexSelected].answerStatus === true
            ) {
                json_data.quiz.correct += 1
                json_data.quiz.totalCorrectPoints +=
                    element.answers[element.indexSelected].punctuation
                // json_data.quiz.incorrect = json_data.quiz.incorrect === 0 ? 0 : json_data.quiz.incorrect -= 1
            } else {
                json_data.quiz.incorrect += 1
            }
        }

        json_data.quiz.percentageCorrect =
            (json_data.quiz.correct / json_data.quiz.totalQuestions) * 100
        json_data.quiz.percentageInCorrect =
            json_data.quiz.incorrect / json_data.quiz.totalQuestions

        if (!json_data.surveyQuizResults) {
            json_data.surveyQuizResults = []
        }
        const newQuiz = {
            totalQuestions: json_data.quiz.totalQuestions,
            correct: json_data.quiz.correct,
            incorrect: json_data.quiz.incorrect,
            notAnswered: json_data.quiz.notAnswered,
            percentageCorrect: json_data.quiz.percentageCorrect,
            percentageInCorrect: json_data.quiz.percentageInCorrect,
            totalPoints: json_data.quiz.totalPoints,
            totalCorrectPoints: json_data.quiz.totalCorrectPoints
        }
        json_data.surveyQuizResults.push(newQuiz)
    }

    public async preSaveForm(): Promise<boolean> {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-beonair-pdf")
            }
            json_data = this.childBridge.json_data
            json_data.forms = json_data.forms || {}
            const SURVEY_QUIZ_FORM: FormID = "survey_quiz"
            if (SURVEY_QUIZ_FORM === this.form_id) {
                try {
                    this.onCalculateSurveyQuizResults()
                    json_data.isSurveyQuizAnswered = true
                } catch (error) {
                    delete json_data.isSurveyQuizAnswered
                    console.log(`Something happens while save survey quiz: ${error}`)
                }
            }
            // if (await this.childBridge.validate()) {
            // }
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
        console.log("childBridge preSaveForm", this.childBridge)

        if (this.form.type === "web") {
            json_data = this.document.json_data
            json_data.forms = json_data.forms || {}
            // if (this.validate()) {
            //     json_data.forms[this.form.form_id] = this.form
            //     const APPLICATION_CAREER_INFORMATION: FormID = "application_career_information"
            //     if (APPLICATION_CAREER_INFORMATION === this.form_id) {
            //         try {
            //             const {
            //                 tuitionDataProgram,
            //                 tuitionCorePlusDataProgram,
            //                 tuitionCorePlusPlusDataProgram
            //             } = await this.getTuitionsByCampusCorePrograms(json_data)
            //             json_data.extra.tuitionInfo = tuitionDataProgram
            //             json_data.extra.tuitionCorePlusInfo = tuitionCorePlusDataProgram
            //             json_data.extra.tuitionCorePlusPlusInfo = tuitionCorePlusPlusDataProgram
            //         } catch (error) {
            //             console.log(`Something happens while get tuitions: ${error}`)
            //         }
            //     }
            // }
        }

        if (!json_data) {
            return false
        }

        console.log("json_data from pre-save:", toJS(json_data))
        console.log("Saving pre-save-form:", this.form_id)
        const id = this.user.doc.id
        let r: any = await this.gate.request(`/docs/${id}/edit`, {
            data: {json_data}
        })
        return r.success
    }
    public async retakeSurveyQuiz(): Promise<boolean> {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-beonair-pdf")
            }
            if (!(await this.childBridge.validate())) {
                await Swal.fire({
                    icon: "error",
                    title: "There are some errors.",
                    text: "Please take a look."
                })
                return false
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
        console.log("childBridge", this.childBridge)

        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form

        console.log("my current JSON DATA from survey quiz:", toJS(json_data))
        delete json_data.quiz
        delete json_data.forms.survey_quiz
        delete json_data.isSubmitted
        console.log("Saving form:", this.form_id)
        const id = this.user.doc.id
        try {
            let r: any = await this.gate.request(`/docs/${id}/quiz/retake`, {
                data: {json_data}
            })
            if (r.success) {
                const {
                    data: {json_data: jsonDataResponse}
                } = r
                json_data.isSurveyQuizRetake = jsonDataResponse.isSurveyQuizRetake
            }
            this.dispatcher.dispatch()
            this.dispatcher2.dispatch()
            return r.success
        } catch (error) {
            console.log(`Something happens while save survey quiz: ${error}`)
        }
    }
    public async save(): Promise<boolean> {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-beonair-pdf")
            }
            if (!(await this.childBridge.validate())) {
                await Swal.fire({
                    icon: "error",
                    title: "There are some errors.",
                    text: "Please take a look."
                })
                return false
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
        console.log("childBridge", this.childBridge)

        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form

        const APPLICATION_CAREER_INFORMATION: FormID = "application_career_information"
        const SURVEY_QUIZ_FORM: FormID = "survey_quiz"
        console.log("my current JSON DATA:", toJS(json_data))

        if (APPLICATION_CAREER_INFORMATION === this.form_id) {
            try {
                const {
                    tuitionDataProgram,
                    tuitionCorePlusDataProgram,
                    tuitionCorePlusPlusDataProgram
                } = await this.getTuitionsByCampusCorePrograms(json_data)
                json_data.extra.tuitionInfo = tuitionDataProgram
                json_data.extra.tuitionCorePlusInfo = tuitionCorePlusDataProgram
                json_data.extra.tuitionCorePlusPlusInfo = tuitionCorePlusPlusDataProgram
                const currentForms = json_data.forms
                json_data.forms = removeJsonDataForms(currentForms || [])
                removeSchedulesIdsFromJsonData(json_data)
            } catch (error) {
                console.log(`Something happens while get tuitions: ${error}`)
            }
        }

        if (SURVEY_QUIZ_FORM === this.form_id) {
            try {
                this.onCalculateSurveyQuizResults()
                json_data.isSurveyQuizAnswered = true
            } catch (error) {
                delete json_data.isSurveyQuizAnswered
                console.log(`Something happens while save survey quiz: ${error}`)
            }
        }

        console.log("Saving form:", this.form_id)

        const id = this.user.doc.id

        let r: any = await this.gate.request(`/docs/${id}/edit`, {
            data: {json_data}
        })
        return r.success
    }

    private async updateTuition(jsonData) {
        const {
            extra: {
                tuitionInfo: {id, tuition}
            }
        } = jsonData
        const tuitionResponse = await this.gate.request(`/tuitions/${id}/edit`, {data: tuition})
        const {
            data: {tuition_data}
        } = tuitionResponse
        return tuition_data
    }

    private async generateTuituonByCampusAndProgramAASRTBCoreProgram(
        campusId,
        programId,
        programInfo
    ) {
        const careerResponse = await this.gate.request(
            `/careers/campuses/${campusId}/programs/${programId}/get`,
            {data: programInfo}
        )
        const {
            data: {tuition_data, schedule_data, condition_text_data}
        } = careerResponse
        console.log("response by campus & program:", careerResponse)

        return {tuition_data, schedule_data, condition_text_data}
    }

    private async generateTuituonByCampusAndProgram(campusId, programId) {
        const careerResponse = await this.gate.request(
            `/careers/campuses/${campusId}/programs/${programId}/get`
        )
        const {
            data: {tuition_data, schedule_data, condition_text_data}
        } = careerResponse
        console.log("response by campus & program:", careerResponse)

        return {tuition_data, schedule_data, condition_text_data}
    }

    public async uploadDocument(file: File, id: string) {
        const IS_MULTI = false
        const formData = new FormData()
        formData.append(id, file)
        const data = await this.gate.request(
            `/uploads`,
            {
                data: formData
            },
            IS_MULTI
        )
        return data
    }

    public signOut() {
        this.parentBridge.signOut()
    }

    public async getSignature(type: "signature" | "initials" | "parent"): Promise<object | null> {
        if (!this.currentSignature) {
            let r = await this.gate.request("/signatures/get-my", {data: {type: type}})
            if (r.success) {
                this.currentSignature = r.data
            } else {
                this.currentSignature = null
            }
        }

        return this.currentSignature
    }

    public addValidateFunction(f: Function) {
        for (let item of this.validateFunctions) {
            if (item === f) {
                return
            }
        }

        this.validateFunctions.push(f)
    }

    public removeValidateFunction(f: Function) {
        console.log("removeValidateFunction")
        // @ts-ignore
        console.log(f.functionId)

        console.log(
            this.validateFunctions.map((e) => {
                // @ts-ignore
                return e.functionId
            })
        )
        for (let i = 0; i < this.validateFunctions.length; i++) {
            // @ts-ignore
            if (this.validateFunctions[i].functionId === f.functionId) {
                this.validateFunctions.splice(i, 1)
                console.log(
                    this.validateFunctions.map((e) => {
                        // @ts-ignore
                        return e.functionId
                    })
                )
                return
            }
        }
    }

    public validate(): boolean {
        console.log("onValidate:", this.validateFunctions)
        let results = []
        for (let f of this.validateFunctions) {
            const r = f()
            results.push(r)
        }

        if (results.includes(false)) {
            return false
        }
        return true
    }

    public async loadVisitor() {
        let r = await this.gate.request("/users/get-me")
        this.visitor = r.data
    }

    public async loadAll(token: string, user_id: number) {
        if (!token || !user_id) {
            return
        }
        this.gate.setToken(token)
        await Promise.all([
            this.loadUser(user_id),
            this.loadVisitor(),
            this.loadCareerInformation(),
            this.loadCampuses()
        ]) // add a control to view if we have campus selected & program selected to refill tuition

        this.document = this.user.doc
        console.log("MODEL>LOAD ALL")
        console.log(toJS(this.document))
        this.form_id =
            (this.document.json_data.progress as FormID) || "application_career_information"

        if (!this.document.json_data.role) {
            this.document.json_data.role = this.user.role
        }
        if (!this.document.json_data.first_name) {
            this.document.json_data.first_name = this.user.first_name
        }
        if (!this.document.json_data.last_name) {
            this.document.json_data.last_name = this.user.last_name
        }

        if (!this.document.json_data.home_phone) {
            this.document.json_data.home_phone = this.user.json_data.phone_number
        }

        if (!this.document.json_data.full_name) {
            this.document.json_data.full_name = `${this.user.first_name} ${this.user.last_name}`
        }
        if (!this.document.json_data.email) {
            this.document.json_data.email = `${this.user.email}`
        }
        if (!this.document.json_data.about_us) {
            this.document.json_data.about_us = `${this.user.json_data.about_us}`
        }
        if (!this.document.json_data.about_us_text) {
            this.document.json_data.about_us_text = `${this.user.json_data.about_us_text}`
        }
        // console.log("autopopulate", toJS(this.document))

        this.dispatcher.dispatch()
        this.dispatcher2.dispatch()
    }
    private getCorePrograms(jsonData) {
        if (jsonData.extra) {
            const {
                extra: {
                    campus: {programs, programsCorePlus, programsCorePlusPlus}
                }
            } = jsonData
            this.programs = programs
            this.programsCorePlus = programsCorePlus
            this.programsCorePlusPlus = programsCorePlusPlus
        }
    }

    private getCurrentProgramsInfoSelected(jsonData) {
        const currentProgramsInfo: any = {}
        if (jsonData) {
            currentProgramsInfo.program = jsonData.program
            currentProgramsInfo.corePlus = jsonData.corePlus
            currentProgramsInfo.corePlusPlus = jsonData.corePlusPlus
            currentProgramsInfo.corePlusProgram = jsonData.corePlusProgram
            currentProgramsInfo.corePlusPlusProgram = jsonData.corePlusPlusProgram
        }
        this.coreProgramsSelectedFromJson = currentProgramsInfo
    }

    private getCoreProgramsInfoFromJsonData() {
        const jsonData = this.document.json_data
        this.getCorePrograms(jsonData)
        this.getCurrentProgramsInfoSelected(jsonData)
    }

    public async deleteSupportedDocument(id: number) {
        const r = await this.gate.request(`/uploads/${id}/delete`)
        return r
    }

    private currentSignature
}

export interface SignatureVO {
    id
    user_id
    json_data: {
        img: string
    }
    ip // "172.17.0.1"
    created_at // "2019-10-17T11:22:43.823Z"
    updated_at // "2019-10-17T11:22:43.823Z"
    deleted_at
}

interface UserDocument {
    id?: number
    // pack_id
    // visitor_id
    // user_id
    // user: User
    // type
    json_data: JsonDocument
    // created_at
    // updated_at
    // deleted_at
}

export interface JsonDocument {
    [key: string]: string | any
}

export interface CareerDocument {
    [key: string]: any
}
